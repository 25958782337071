(function(g, f) { var r = (typeof require === 'function' ? require : function(name) { return {"jquery":jQuery}[name]; }); if (typeof exports === 'object' && typeof module !== 'undefined') { module.exports = f(r) } else if (typeof define === 'function' && define.amd) { define(["jquery"], f.bind(g,r)) } else { g.jQueryClickout = f(r) } })(this, function(require,define, module,exports) { return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/**
 * jQuery Clickout Plugin
 * @version 0.0.1
 * @author Guilherme Santiago - github.com/gsantiago
 */

var $ = require('jquery')
var $document = $(document)
var hash = {}
var setup = false

$.event.special.clickout = {
  setup: function (data, namespaces, eventHandle) {
    if (setup) return

    $document.on('click.clickout-handler', function (event) {
      $.each(hash, function (index, obj) {
        var condition = true

        $.each(obj.elements, function (i, el) {
          if ($(event.target).closest(el).length) condition = false
        })

        if (condition) {
          obj.handler.call($(obj.elements), event)
        }
      })
    })

    setup = true
  },

  teardown: function () {
    if (hash) return
    $document.off('click.clickout-handler')
    setup = false
  },

  add: function (handleObj) {
    var guid = handleObj.guid
    if (!hash.hasOwnProperty(guid)) {
      hash[guid] = {elements: [this], handler: handleObj.handler}
    } else {
      hash[guid].elements.push(this)
    }
  },

  remove: function (handleObj) {
    delete hash[handleObj.guid]
  }
}

},{"jquery":"jquery"}]},{},[1])

(1); });